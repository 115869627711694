import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment.prod';
import { Observable } from 'rxjs';
import { User } from './services/Users';
import { Rol } from './services/Rol';
import { Puesto } from './services/Puesto';
import { Trabajador } from './services/Trabajador';
import { Patrono } from './services/Patrono';
import { Pais } from './services/Pais';
import { Departamento } from './services/Departamento';
import { Municipio } from './services/Municipio';
import { Profesiones } from './services/Profesion';
import { Etnia } from './services/Etnia';
import { Adjudicacion } from './services/Adjudicacion';
import { Motivo } from './services/Motivo';
import { Submotivo } from './services/Submotivo';
import { Tema } from './services/Tema';
import { Subtema } from './services/Subtema';
import { Direccion } from './services/Direccion';
import { Contacto } from './services/Contacto';
import { Traxexp } from './services/Traxexp';
import { Empxexp } from './services/Empxexp';
import { Docxexp } from './services/Docxexp';
import { Permiso } from './services/Permiso';
import { Permisosxrol } from './services/Permisosxrol';
import { Rolesxuser } from './services/Rolesxuser';
import { Region } from './services/Region';
import { Dependencia } from './services/Dependencia';
import { Insxdep } from './services/Insxdep';
import { Tipodoc } from './services/Tipodoc';
import { Tipoperj } from './services/Tipoperj';
import { Tipodir } from './services/Tipodir';
import { Escolaridad } from './services/Escolaridad';
import { Bitacora } from './services/Bitacora';
import { Sectoreco } from './services/Sectoreco';
import { Actesp } from './services/Actesp';
import { Documento } from './services/Documento';
import { Zona } from './services/Zona';
import { Faltas } from './services/Faltas';
import { Prevenciones } from './services/Prevenciones';
import { Parametro } from './services/Parametro';
import { Normas } from './services/Normas';
import { Fundamentos } from './services/Fundamentos';
import { Verificacion } from './services/Verificacion';
import { Ruta } from './services/Ruta';
import { Inspeccion } from './services/Inspeccion';
import { Criterio } from './services/Criterios';
import { Medidas } from './services/Medidas';
import { Pagos } from './services/Pagos';
import { Banco } from './services/Banco';
import { Operativo } from './services/Operativo';
import { Notificacion } from './services/Notificacion';
import { Reporte } from './services/Reporte';
import { Leyes } from './services/Leyes';
import { Prestacion } from './services/Prestacion';
import { Tag } from './services/Tag';
import { Salario } from './services/Salario';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  base = environment.base; 
  constructor(private http:HttpClient) { }

  login(datosUser:User):Observable<any>{
    return this.http.post(`${this.base}login`,datosUser);
  }
  //USUARIOS
  getUsers():any{
    return this.http.get<User[]>(`${this.base}users`);
  }
  getUsers2():any{
    return this.http.get<User[]>(`${this.base}users2`);
  }
  AgregarUser(datosUser:User):Observable<any>{
    return this.http.post(`${this.base}register`,datosUser);
  }
  DeleteUser(id:any):Observable<any>{
    return this.http.delete(`${this.base}userdelete/${id}`);
  }
  GetUserId(id:any):Observable<any>{
    return this.http.get(`${this.base}userbyid/${id}`);
  }
  updateUser(user:User){
    return this.http.put<User>(`${this.base}user-update`,user);
  }
  getinfo(user:User){
    return this.http.post<User>(`${this.base}getinfo`,user);
  }
  subirImagenUser(imagen:FormData){
    //console.log(imagen.get('file'));
    return this.http.post<User>(`${this.base}subirFoto`,imagen);
  }
  /* PERMISOS */
  getPermiso(id:any):Observable<any>{
    return this.http.get<Permiso[]>(`${this.base}view-permisos/${id}`);
  }
  /* PERMISOS x ROL */
  getPermisoxrol(id:any):any{
    return this.http.get<Permisosxrol[]>(`${this.base}view-permisosxrol/${id}`);
  }
  getbyuser(id:any):any{
    return this.http.get<Permisosxrol[]>(`${this.base}getbyuser/${id}`);
  }
  DeletePermisoxrol(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-permisosxrol/${id}`);
  }
  AgregarPermisoxrol(datosUser:Permisosxrol):Observable<any>{
    return this.http.post(`${this.base}add-permisoxrol`,datosUser);
  }
  /* ROL x USER */
  getRolxuser(id:any):any{
    return this.http.get<Rolesxuser[]>(`${this.base}view-rolesxuser/${id}`);
  }
  DeleteRolxuser(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-rolesxuser/${id}`);
  }
  AgregarRolxuser(datosUser:Rolesxuser):Observable<any>{
    return this.http.post(`${this.base}add-rolxuser`,datosUser);
  }
  /* TRABAJADOR */
  getTrabajador():any{
    return this.http.get<Trabajador[]>(`${this.base}view-trabajador`);
  }
  getTrabajador2(id:any):any{
    return this.http.get<Trabajador[]>(`${this.base}view-trabajador2/${id}`);
  }
  DeleteTrabajador(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-trabajador/${id}`);
  }
  AgregarTrabajador(datosTrabajador:Trabajador):Observable<any>{
    return this.http.post(`${this.base}add-trabajador`,datosTrabajador);
  }
  GetTrabajadorId(id:any):Observable<any>{
    return this.http.get(`${this.base}trabajadorbyid/${id}`);
  }
  updateTrabajador(trabajador:Trabajador){
    return this.http.post(`${this.base}trabajador-update`,trabajador);
  }

  subirArchivo(imagen:FormData){
    //console.log(imagen.get('file'));
    return this.http.post<Trabajador>(`${this.base}upload`,imagen);
  }

  
  /* PATRONO */
  getPatrono():any{
    return this.http.get<Patrono[]>(`${this.base}view-patrono`);
  }
  getPatrono2(id:any):any{
    return this.http.get<Patrono[]>(`${this.base}view-patrono2/${id}`);
  }
  DeletePatrono(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-patrono/${id}`);
  }
  AgregarPatrono(datosPatrono:Patrono):Observable<any>{
    return this.http.post(`${this.base}add-patronoi`,datosPatrono);
  }
  AgregarPatrono1(datosPatrono:Patrono):Observable<any>{
    return this.http.post(`${this.base}add-patronoi`,datosPatrono);
  }
  AgregarPatrono2(datosPatrono:Patrono):Observable<any>{
    return this.http.post(`${this.base}add-patronoj`,datosPatrono);
  }
  GetPatronoId(id:any):Observable<any>{
    return this.http.get(`${this.base}patronobyid/${id}`);
  }
  updatePatrono(patrono:Patrono){
    console.log(patrono);
    return this.http.put<Patrono>(`${this.base}patrono-update`,patrono);
  }
  updatePatronoj(patrono:Patrono){
    console.log(patrono);
    return this.http.put<Patrono>(`${this.base}patrono-updatej`,patrono);
  }
  /* PAIS */
  getPais():any{
    return this.http.get<Pais[]>(`${this.base}view-pais`);
  }
  DeletePais(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-pais/${id}`);
  }
  AgregarPais(datosPais:Pais):Observable<any>{
    return this.http.post(`${this.base}add-pais`,datosPais);
  }
  GetPaisId(id:any):Observable<any>{
    return this.http.get(`${this.base}paisbyid/${id}`);
  }
  updatePais(pais:Pais){
    return this.http.put<Pais>(`${this.base}pais-update`,pais);
  }
  /* DEPARTAMENTO */
  getDepartamento(id:any):any{
    return this.http.get<Departamento[]>(`${this.base}view-departamento/${id}`);
  }
  DeleteDepartamento(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-departamento/${id}`);
  }
  AgregarDepartamento(datosDepartamento:Departamento):Observable<any>{
    return this.http.post(`${this.base}add-departamento`,datosDepartamento);
  }
  GetDepartamentoId(id:any):Observable<any>{
    return this.http.get(`${this.base}departamentobyid/${id}`);
  }
  updateDepartamento(departamento:Departamento){
    return this.http.put<Departamento>(`${this.base}departamento-update`,departamento);
  }
  /* MUNICIPIO */
  getMunicipio(id:any):any{
    return this.http.get<Municipio[]>(`${this.base}view-municipio/${id}`);
  }
  DeleteMunicipio(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-municipio/${id}`);
  }
  AgregarMunicipio(datosMunicipio:Municipio):Observable<any>{
    return this.http.post(`${this.base}add-municipio`,datosMunicipio);
  }
  GetMunicipioId(id:any):Observable<any>{
    return this.http.get(`${this.base}municipiobyid/${id}`);
  }
  updateMunicipio(municipio:Municipio){
    return this.http.put<Municipio>(`${this.base}municipio-update`,municipio);
  }
  /* PUESTOS */
  getPuesto():any{
    return this.http.get<Puesto[]>(`${this.base}view-puesto`);
  }
  DeletePuesto(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-puesto/${id}`);
  }
  AgregarPuesto(datosPuesto:Puesto):Observable<any>{
    return this.http.post(`${this.base}add-puesto`,datosPuesto);
  }
  GetPuestoId(id:any):Observable<any>{
    return this.http.get(`${this.base}puestobyid/${id}`);
  }
  updatePuesto(puesto:Puesto){
    return this.http.put<Puesto>(`${this.base}puesto-update`,puesto);
  }
  /* SALARIOS MINIMOS */
  getSalmin():any{
    return this.http.get<Salario[]>(`${this.base}view-salmin`);
  }
  DeleteSalmin(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-salmin/${id}`);
  }
  AgregarSalmin(datosSalario:Salario):Observable<any>{
    return this.http.post(`${this.base}add-salmin`,datosSalario);
  }
  GetSalminId(id:any):Observable<any>{
    return this.http.get(`${this.base}salminbyid/${id}`);
  }
  GetSalminId2(id:any):Observable<any>{
    return this.http.get(`${this.base}salminbyid2/${id}`);
  }
  updateSalmin(salario:Salario){
    return this.http.put<Salario>(`${this.base}salmin-update`,salario);
  }
  /* PROFESION */
  getProfesion():any{
    return this.http.get<Profesiones[]>(`${this.base}view-profesion`);
  }
  DeleteProfesion(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-profesion/${id}`);
  }
  AgregarProfesion(datosProfesion:Profesiones):Observable<any>{
    return this.http.post(`${this.base}add-profesion`,datosProfesion);
  }
  GetProfesionId(id:any):Observable<any>{
    return this.http.get(`${this.base}profesionbyid/${id}`);
  }
  updateProfesion(profesion:Profesiones){
    return this.http.put<Profesiones>(`${this.base}profesion-update`,profesion);
  }
  /* ROLES */
  getRol():any{
    return this.http.get<Rol[]>(`${this.base}view-rol`);
  }
  getRol2(id:any):any{
    return this.http.get<Rol[]>(`${this.base}view-rol2/${id}`);
  }
  DeleteRol(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-rol/${id}`);
  }
  AgregarRol(datosRol:Rol):Observable<any>{
    return this.http.post(`${this.base}add-rol`,datosRol);
  }
  GetRolId(id:any):Observable<any>{
    return this.http.get(`${this.base}rolbyid/${id}`);
  }
  updateRol(rol:Rol){
    return this.http.put<Rol>(`${this.base}rol-update`,rol);
  }

  /* ANOTACIONES */
  getAnotacion(id:any):any{
    return this.http.get<Docxexp[]>(`${this.base}anotaciones-view/${id}`);
  }
  DeleteAnotacion(id:any):Observable<any>{
    return this.http.delete(`${this.base}anotaciones-delete/${id}`);
  }
  AgregarAnotacion(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}anotaciones-add`,archive);
  }
  /* Adjudicacion */
  getAdjudicacion(id:any):any{
    return this.http.get<Adjudicacion[]>(`${this.base}view-expediente/${id}`);
  }
  DeleteAdjudicacion(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-expediente/${id}`);
  }
  AgregarAdjudicacion(datosAdjudicacion:Adjudicacion):Observable<any>{
    return this.http.post(`${this.base}add-expediente`,datosAdjudicacion);
  }
  GetAdjudicacionId(id:any):Observable<any>{
    return this.http.get(`${this.base}expedientebyid/${id}`);
  }
  updateAdjudicacion(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-update`,adjudicacion);
  }
  updateAdjudicacion2(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente2-update`,adjudicacion);
  }
  updateAdjudicacion3(archive:FormData){
    return this.http.post<Adjudicacion>(`${this.base}expediente3-update`,archive);
  }
  generarCaratula(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-generarcaratula`,adjudicacion);
  }
  generarInspectorExo(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-generarinspectorexo`,adjudicacion);
  }
  generarAnalistaExo(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-generaranalistaexo`,adjudicacion);
  }
  generarAsesor(adjudicacion:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-generarasesor`,adjudicacion);
  }
  AgregarArchivo(adjudicacion:Adjudicacion){
    return this.http.post<Adjudicacion>(`${this.base}expediente-archivo`,adjudicacion);
  }
  FinalizarSancion(id:any):any{
    return this.http.get(`${this.base}finalizar-sancion/${id}`);
  }
  Ejecutar(id:any):any{
    return this.http.get(`${this.base}eje-exp/${id}`);
  }
  getDeptoMunXUS(id:any):any{
    return this.http.get<Adjudicacion[]>(`${this.base}view-deptomunxus/${id}`);
  }
  /* Motivo */
  getMotivo(id:any):any{
    return this.http.get<Motivo[]>(`${this.base}view-motivo/${id}`);
  }
  getMotivoG():any{
    return this.http.get<Motivo[]>(`${this.base}view-motivoG`);
  }
  DeleteMotivo(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-motivo/${id}`);
  }
  AgregarMotivo(datosMotivo:Motivo):Observable<any>{
    return this.http.post(`${this.base}add-motivo`,datosMotivo);
  }
  GetMotivoId(id:any):Observable<any>{
    return this.http.get(`${this.base}motivobyid/${id}`);
  }
  updateMotivo(motivo:Motivo){
    return this.http.put<Motivo>(`${this.base}motivo-update`,motivo);
  }
  /* Submotivo */
  getSubmotivo(id:any):any{
    return this.http.get<Submotivo[]>(`${this.base}view-submotivo/${id}`);
  }
  DeleteSubmotivo(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-submotivo/${id}`);
  }
  AgregarSubmotivo(datosSubmotivo:Submotivo):Observable<any>{
    return this.http.post(`${this.base}add-submotivo`,datosSubmotivo);
  }
  GetSubmotivoId(id:any):Observable<any>{
    return this.http.get(`${this.base}submotivobyid/${id}`);
  }
  updateSubmotivo(submotivo:Submotivo){
    return this.http.put<Submotivo>(`${this.base}submotivo-update`,submotivo);
  }
  /* Tema */
  getTema():any{
    return this.http.get<Tema[]>(`${this.base}view-tema`);
  }
  DeleteTema(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-tema/${id}`);
  }
  AgregarTema(datosTema:Tema):Observable<any>{
    return this.http.post(`${this.base}add-tema`,datosTema);
  }
  GetTemaId(id:any):Observable<any>{
    return this.http.get(`${this.base}temabyid/${id}`);
  }
  updateTema(tema:Tema){
    return this.http.put<Tema>(`${this.base}tema-update`,tema);
  }
  /* Subtema */
  getSubtema(id:any):any{
    return this.http.get<Subtema[]>(`${this.base}view-subtema/${id}`);
  }
  DeleteSubtema(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-subtema/${id}`);
  }
  AgregarSubtema(datosSubtema:Subtema):Observable<any>{
    return this.http.post(`${this.base}add-subtema`,datosSubtema);
  }
  GetSubtemaId(id:any):Observable<any>{
    return this.http.get(`${this.base}subtemabyid/${id}`);
  }
  updateSubtema(subtema:Subtema){
    return this.http.put<Subtema>(`${this.base}subtema-update`,subtema);
  }

  /* Expediente */
  getExpediente():any{
    return this.http.get<Subtema[]>(`${this.base}view-expediente`);
  }
  DeleteExpediente(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-expediente/${id}`);
  }
  AgregarExpediente(datosAdjudicacion:Adjudicacion):Observable<any>{
    return this.http.post(`${this.base}add-expediente`,datosAdjudicacion);
  }
  GetExpedienteId(id:any):Observable<any>{
    return this.http.get(`${this.base}expedientebyid/${id}`);
  }
  GetExpedienteId2(id:any):Observable<any>{
    return this.http.get<Adjudicacion[]>(`${this.base}expedientebyid2/${id}`);
  }
  GetExpedienteId3(id:any):Observable<any>{
    return this.http.get<Adjudicacion[]>(`${this.base}expedientebyid3/${id}`);
  }
  
  AprobarExp(id:any):Observable<any>{
    return this.http.get<Adjudicacion>(`${this.base}expedienteaprobar/${id}`);
  }
  desAprobarExp(id:any):Observable<any>{
    return this.http.get<Adjudicacion>(`${this.base}expedientedesaprobar/${id}`);
  }
  updateExpediente(expediente:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}expediente-update`,expediente);
  }

  FinalizarExp(id:any):any{
    return this.http.get(`${this.base}fin-exp/${id}`);
  }
  JudicializarExp(id:any):any{
    return this.http.get(`${this.base}jud-exp/${id}`);
  }
  ImpugnarExp(id:any):any{
    return this.http.get(`${this.base}imp-exp/${id}`);
  }

  ArchivoExp(id:any):any{
    return this.http.get(`${this.base}arc-exp/${id}`);
  }
  SancionarExp(id:any):any{
    return this.http.get(`${this.base}san-exp/${id}`);
  }
  RevisarExp(id:any):any{
    return this.http.get(`${this.base}rev-exp/${id}`);
  }
  AutorizarExp(id:any):any{
    return this.http.get(`${this.base}aut-exp/${id}`);
  }
  DenegarExp(id:any):any{
    return this.http.get(`${this.base}den-exp/${id}`);
  }

  getImpugnaciones(id:any):any{
    return this.http.get<Adjudicacion[]>(`${this.base}view-impugnaciones/${id}`);
  }
  
  /* Inspección */
  getInspeccion(id:any):any{
    return this.http.get<Adjudicacion[]>(`${this.base}view-inspeccion/${id}`);
  }
  getInspeccion2(id:any):any{
    return this.http.get<Adjudicacion[]>(`${this.base}view-inspeccion2/${id}`);
  }
  DeleteInspeccion(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-inspeccion/${id}`);
  }
  AgregarInspeccion(id:any):Observable<any>{
    return this.http.get<Adjudicacion>(`${this.base}add-inspeccion/${id}`);
  }
  GetInspeccionId(id:any):Observable<any>{
    return this.http.get(`${this.base}inspeccionbyid/${id}`);
  }
  AprobarIns(id:any):Observable<any>{
    return this.http.get<Adjudicacion>(`${this.base}inspeccionaprobar/${id}`);
  }
  desAprobarIns(id:any):Observable<any>{
    return this.http.get<Adjudicacion>(`${this.base}inspecciondesaprobar/${id}`);
  }
  updateInspeccion(expediente:Adjudicacion){
    return this.http.put<Adjudicacion>(`${this.base}inspeccion-update`,expediente);
  }
  /* DIRECCIONES */
  getDireccion(id:any):Observable<any>{
    return this.http.get(`${this.base}view-direccion/${id}`);
  }
  AgregarDireccion(datosDireccion:Direccion):Observable<any>{
    return this.http.post(`${this.base}add-direccion`,datosDireccion);
  }
  DeleteDireccion(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-direccion/${id}`);
  }  
  GetDireccionId(id:any):Observable<any>{
    return this.http.get(`${this.base}direccionbyid/${id}`);
  }
  updateDireccion(subtema:Subtema){
    return this.http.put<Subtema>(`${this.base}direccion-update`,subtema);
  }
  /* Contactos */
  getContacto(id:any):Observable<any>{
    return this.http.get(`${this.base}view-contacto/${id}`);
  }
  AgregarContacto(datosContacto:Contacto):Observable<any>{
    return this.http.post(`${this.base}add-contacto`,datosContacto);
  }
  DeleteContacto(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-contacto/${id}`);
  }
  GetContactoId(id:any):Observable<any>{
    return this.http.get(`${this.base}contactobyid/${id}`);
  }
  updateContacto(contacto:Contacto){
    return this.http.put<Contacto>(`${this.base}contacto-update`,contacto);
  }
  /* Contactos2 */
  getContactoT(id:any):Observable<any>{
    return this.http.get(`${this.base}view-contacto2/${id}`);
  }
  AgregarContactoT(datosContacto:Contacto):Observable<any>{
    return this.http.post(`${this.base}add-contacto2`,datosContacto);
  }
  DeleteContactoT(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-contacto2/${id}`);
  }
  GetContactoTId(id:any):Observable<any>{
    return this.http.get(`${this.base}contacto2byid/${id}`);
  }
  updateContactoT(contacto:Contacto){
    return this.http.put<Contacto>(`${this.base}contacto2-update`,contacto);
  }
  /* DIRECCIONES */
  getDireccionT(id:any):Observable<any>{
    return this.http.get(`${this.base}view-direccion2/${id}`);
  }
  AgregarDireccionT(datosDireccion:Direccion):Observable<any>{
    return this.http.post(`${this.base}add-direccion2`,datosDireccion);
  }
  DeleteDireccionT(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-direccion2/${id}`);
  }
  GetDireccionTId(id:any):Observable<any>{
    return this.http.get(`${this.base}direccion2byid/${id}`);
  }
  updateDireccionT(direccion:Direccion){
    return this.http.put<Direccion>(`${this.base}direccion2-update`,direccion);
  }
  /* MOTIVOEXP */
  getMotivoexp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-motivoexp/${id}`);
  }
  getSubMotivoexp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-submotivoexp/${id}`);
  }
  AgregarMotivoexp(datosDireccion:Direccion):Observable<any>{
    return this.http.post(`${this.base}add-motivoexp`,datosDireccion);
  }
  DeleteMotivoexp(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-motivoexp/${id}`);
  }
  /* SUBMOTIVOEXP */
  getSubMotivoexp2(id:any):Observable<any>{
    return this.http.get(`${this.base}view-submotivoexp2/${id}`);
  }
  AgregarSubMotivoexp(datosDireccion:Direccion):Observable<any>{
    return this.http.post(`${this.base}add-submotivoexp`,datosDireccion);
  }
  DeleteSubMotivoexp(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-submotivoexp/${id}`);
  }
  /* TRABAJADOR x EXP */
  getTrabajadorexp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-trabajadorexp/${id}`);
  }
  AgregarTrabajadorexp(datosTraexp:Traxexp):Observable<any>{
    return this.http.post(`${this.base}add-trabajadorexp`,datosTraexp);
  }
  DeleteTrabajadorexp(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-trabajadorexp/${id}`);
  }
  /* EMPLEADOR x EXP */
  getEmpleadorexp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-patronoexp/${id}`);
  }
  getEmpleadorexp2(id:any):Observable<any>{
    return this.http.get(`${this.base}view-patronoexp2/${id}`);
  }
  AgregarEmpleadorexp(datosEmpxexp:Empxexp):Observable<any>{
    return this.http.post(`${this.base}add-patronoexp`,datosEmpxexp);
  }
  DeleteEmpleadorexp(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-patronoexp/${id}`);
  }
  /* Documento x EXP */
  getDocumentoexp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-documentoexp/${id}`);
  }
  AgregarDocumentoexp(datosEmpxexp:Docxexp):Observable<any>{
    return this.http.post(`${this.base}add-documentoexp`,datosEmpxexp);
  }
  DeleteDocumentoexp(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-documentoexp/${id}`);
  }
  subirArchivoDoc(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive`,archive);
  }



  subirArchivoPago(archive:FormData){
    return this.http.post<Pagos>(`${this.base}upload-pagos`,archive);
  }

  /* Documento x Verificacion Multa */
  getDocumentoexp2(id:any):Observable<any>{
    return this.http.get(`${this.base}view-documentoexp2/${id}`);
  }
  AgregarDocumentoexp2(datosEmpxexp:Docxexp):Observable<any>{
    return this.http.post(`${this.base}add-documentoexp2`,datosEmpxexp);
  }
  DeleteDocumentoexp2(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-documentoexp2/${id}`);
  }
  subirArchivoDoc2(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive2`,archive);
  }

  /* IMPUGNACIONES */
  subirArchivoRecurso(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive-recurso`,archive);
  }
  subirArchivoProvidencia(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive-providencia`,archive);
  }
  subirArchivoDictamen(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive-dictamen`,archive);
  }
  subirArchivoResolucion(archive:FormData){
    return this.http.post<Docxexp>(`${this.base}upload-archive-resolucion`,archive);
  }

  getRecursos(id:any):Observable<any>{
    return this.http.get(`${this.base}view-recursos/${id}`);
  }
  getProvidencias(id:any):Observable<any>{
    return this.http.get(`${this.base}view-providencias/${id}`);
  }
  getDictamenes(id:any):Observable<any>{
    return this.http.get(`${this.base}view-dictamenes/${id}`);
  }
  getResoluciones(id:any):Observable<any>{
    return this.http.get(`${this.base}view-resoluciones/${id}`);
  }
  /* Region */
  getRegion():Observable<any>{
    return this.http.get(`${this.base}view-region`);
  }
  AgregarRegion(region:Region):Observable<any>{
    return this.http.post(`${this.base}add-region`,region);
  }
  DeleteRegion(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-region/${id}`);
  }
  GetRegionId(id:any):Observable<any>{
    return this.http.get(`${this.base}regionbyid/${id}`);
  }
  updateRegion(region:Region){
    return this.http.put<Region>(`${this.base}region-update`,region);
  }
  /* Dependencia */
  getDependencia(id:any):Observable<any>{
    return this.http.get(`${this.base}view-dependencia/${id}`);
  }
  AgregarDependencia(dependencia:Dependencia):Observable<any>{
    return this.http.post(`${this.base}add-dependencia`,dependencia);
  }
  DeleteDependencia(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-dependencia/${id}`);
  }
  GetDependenciaId(id:any):Observable<any>{
    return this.http.get(`${this.base}dependenciabyid/${id}`);
  }
  updateDependencia(dependencia:Dependencia){
    return this.http.put<Dependencia>(`${this.base}dependencia-update`,dependencia);
  }
  /* Inspecto x Dependencia */
  getInsxdepe(id:any):Observable<any>{
    return this.http.get(`${this.base}view-insxdepe/${id}`);
  }
  AgregarInsxdepe(insxdepe:Insxdep):Observable<any>{
    return this.http.post(`${this.base}add-insxdepe`,insxdepe);
  }
  DeleteInsxdepe(id:any):Observable<any>{    
    return this.http.delete(`${this.base}delete-insxdepe/${id}`);
  }
  /* ETNIA */
  getEtnia():any{
    return this.http.get<Etnia[]>(`${this.base}view-etnia`);
  }
  /* Tipo de documento */
  getTipodoc(id:any):any{
    return this.http.get<Tipodoc[]>(`${this.base}view-tipodoc/${id}`);
  }
  /* Tipo de persona juridica */
  getTipoPerj():any{
    return this.http.get<Tipoperj[]>(`${this.base}view-tipoperj`);
  }
  /* Tipo dirección */
  getTipoDir():any{
    return this.http.get<Tipodir[]>(`${this.base}view-tipodir`);
  }
  /* Escolaridad */
  getEscolaridad():any{
    return this.http.get<Escolaridad[]>(`${this.base}view-escolaridad`);
  }
  /* Bitacora */
  getBitacora():any{
    return this.http.get<Bitacora[]>(`${this.base}view-bitacora`);
  }
  AgregarBitacora(bitacora:Bitacora):Observable<any>{
    return this.http.post(`${this.base}add-bitacora`,bitacora);
  }
  AgregarBitacora2(bitacora:Bitacora):Observable<any>{
    return this.http.post(`${this.base}add-bitacora2`,bitacora);
  }
  /* Sector Economico */
  getSectorEco():any{
    return this.http.get<Sectoreco[]>(`${this.base}view-sectoreco`);
  }
  DeleteSectorEco(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-sectoreco/${id}`);
  }
  AgregarSectorEco(sectoreco:Sectoreco):Observable<any>{
    return this.http.post(`${this.base}add-sectoreco`,sectoreco);
  }
  GetSectorEcoId(id:any):Observable<any>{
    return this.http.get(`${this.base}sectorecobyid/${id}`);
  }
  updateSectorEco(sectoreco:Sectoreco){
    return this.http.put<Sectoreco>(`${this.base}sectoreco-update`,sectoreco);
  }
  /* Actividad Especifica */
  getActEsp(id:any):any{
    return this.http.get<Actesp[]>(`${this.base}view-actesp/${id}`);
  }
  getActEsp2():any{
    return this.http.get<Actesp[]>(`${this.base}view-actesp2`);
  }
  DeleteActEsp(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-actesp/${id}`);
  }
  AgregarActEsp(actesp:Actesp):Observable<any>{
    return this.http.post(`${this.base}add-actesp`,actesp);
  }
  GetActEspId(id:any):Observable<any>{
    return this.http.get(`${this.base}actespbyid/${id}`);
  }
  updateActEsp(actesp:Actesp){
    return this.http.put<Actesp>(`${this.base}actesp-update`,actesp);
  }
  /* Documentos */
  getDocumentos():any{
    return this.http.get<Documento[]>(`${this.base}view-documentos`);
  }
  DeleteDocumentos(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-documentos/${id}`);
  }
  AgregarDocumentos(documentos:Documento):Observable<any>{
    return this.http.post(`${this.base}add-documentos`,documentos);
  }
  GetDocumentosId(id:any):Observable<any>{
    return this.http.get(`${this.base}documentosbyid/${id}`);
  }
  updateDocumentos(documentos:Documento){
    return this.http.put<Documento>(`${this.base}documentos-update`,documentos);
  }
  subirDocumento(archive:FormData){
    return this.http.post<Documento>(`${this.base}upload-documento`,archive);
  }
  /* Zona */
  getZona(id:any):any{
    return this.http.get<Zona[]>(`${this.base}view-zona/${id}`);
  }
  DeleteZona(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-zona/${id}`);
  }
  AgregarZona(zona:Zona):Observable<any>{
    return this.http.post(`${this.base}add-zona`,zona);
  }
  GetZonaId(id:any):Observable<any>{
    return this.http.get(`${this.base}zonabyid/${id}`);
  }
  updateZona(zona:Zona){
    return this.http.put<Zona>(`${this.base}zona-update`,zona);
  }
  /* Fundamentos */
  getFundamentos():any{
    return this.http.get<Fundamentos[]>(`${this.base}view-fundamentos`);
  }
  DeleteFundamentos(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-fundamentos/${id}`);
  }
  AgregarFundamentos(fundamentos:Fundamentos):Observable<any>{
    return this.http.post(`${this.base}add-fundamentos`,fundamentos);
  }
  GetFundamentosId(id:any):Observable<any>{
    return this.http.get(`${this.base}Fundamentosbyid/${id}`);
  }
  updateFundamentos(fundamentos:Fundamentos){
    return this.http.put<Fundamentos>(`${this.base}fundamentos-update`,fundamentos);
  }
  /* Normas */
  getNormas(id:any):any{
    return this.http.get<Normas[]>(`${this.base}view-normas/${id}`);
  }
  DeleteNormas(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-normas/${id}`);
  }
  AgregarNormas(normas:Normas):Observable<any>{
    return this.http.post(`${this.base}add-normas`,normas);
  }
  GetNormasId(id:any):Observable<any>{
    return this.http.get(`${this.base}normasbyid/${id}`);
  }
  updateNorma(normas:Normas){
    return this.http.put<Normas>(`${this.base}normas-update`,normas);
  }
  /* Faltas */
  getFaltas(id:any):any{
    return this.http.get<Faltas[]>(`${this.base}view-faltas/${id}`);
  }
  DeleteFaltas(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-faltas/${id}`);
  }
  AgregarFaltas(faltas:Faltas):Observable<any>{
    return this.http.post(`${this.base}add-faltas`,faltas);
  }
  GetFaltasId(id:any):Observable<any>{
    return this.http.get(`${this.base}faltasbyid/${id}`);
  }
  updateFaltas(faltas:Faltas){
    return this.http.put<Faltas>(`${this.base}faltas-update`,faltas);
  }
  /* Operativos */
  getOperativos():any{
    return this.http.get<Operativo[]>(`${this.base}view-operativos`);
  }
  getOperativos2(operativo:Operativo):Observable<any>{
    return this.http.post(`${this.base}get-operativos`,operativo);
  }
  DeleteOperativos(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-operativos/${id}`);
  }
  AgregarOperativos(operativo:Operativo):Observable<any>{
    return this.http.post(`${this.base}add-operativos`,operativo);
  }
  GetOperativosId(id:any):Observable<any>{
    return this.http.get(`${this.base}operativosbyid/${id}`);
  }
  updateOperativos(operativo:Operativo){
    return this.http.put<Operativo>(`${this.base}operativos-update`,operativo);
  }
  /* Notificaciones */
  getNotificacion(id:any):any{
    return this.http.get<Notification[]>(`${this.base}view-notificacion/${id}`);
  }
  getResultado(id:any):Observable<any>{
    return this.http.get(`${this.base}view-resultado/${id}`);
  }
  getNotificaciones1():any{
    return this.http.get<Notification[]>(`${this.base}view-notificaciones1`);
  }
  getNotificaciones2():any{
    return this.http.get<Notification[]>(`${this.base}view-notificaciones2`);
  }
  getNotificaciones3(id:any):any{
    return this.http.get<Notification[]>(`${this.base}view-notificaciones3/${id}`);
  }
  AsignarNotificador(notificacion:Notificacion):Observable<any>{
    return this.http.post(`${this.base}asignar-notificador`,notificacion);
  }
  AnularNotificacion(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}anular-notificacion/${id}`);
  }
  DeleteNotificaciones(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-notificaciones/${id}`);
  }
  DeleteNotificacion(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-notificacion/${id}`);
  }
  DeleteNotificacionExp(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-notificacionexp/${id}`);
  }
  AgregarNotificacion(notificacion:Notificacion):Observable<any>{
    return this.http.post(`${this.base}add-notificacion`,notificacion);
  }
  GetNotificacionId(id:any):Observable<any>{
    return this.http.get(`${this.base}notificacionbyid/${id}`);
  }
  updateNotificacion(notificacion:Notification){
    return this.http.put<Notificacion>(`${this.base}notificacion-update`,notificacion);
  }
  cambioNotificacion(notificacion:Notification){
    return this.http.put<Notificacion>(`${this.base}notificacion-cambio`,notificacion);
  }
  subirArchivoNotificacion(archive:FormData){
    return this.http.post<Notificacion>(`${this.base}upload-notificacion`,archive);
  }
  subirArchivoCel(imagen:FormData){
    //console.log(imagen.get('file'));
    return this.http.post<Notificacion>(`${this.base}upload-cedula`,imagen);
  }
  AgregarEmpleadornot(datosEmpxexp:Empxexp):Observable<any>{
    return this.http.post(`${this.base}add-patrononot`,datosEmpxexp);
  }
  
  AgregarTrabajadornot(datosTraexp:Traxexp):Observable<any>{
    return this.http.post(`${this.base}add-trabajadornot`,datosTraexp);
  }

  getNotificacionExp(id:any):Observable<any>{
    return this.http.get(`${this.base}view-notificacionexp/${id}`);
  }

  getNotificadores(id:any):Observable<any>{
    return this.http.get(`${this.base}view-notificadores/${id}`);
  }
  
  /* Prevenciones */
  getPrevenciones(id:any):any{
    return this.http.get<Prevenciones[]>(`${this.base}view-prevenciones/${id}`);
  }
  getPrevencionesG(id:any):any{
    return this.http.get<Prevenciones[]>(`${this.base}view-prevencionesG/${id}`);
  }
  getPrevencionesG2(id:any):any{
    return this.http.get<Prevenciones[]>(`${this.base}view-prevencionesG2/${id}`);
  }
  getPrevencionesG3(id:any):any{
    return this.http.get<Prevenciones[]>(`${this.base}view-prevencionesG3/${id}`);
  }
  getPrevencionesG4(id:any):any{
    return this.http.get<Prevenciones[]>(`${this.base}view-prevencionesG4/${id}`);
  }
  DeletePrevenciones(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-prevenciones/${id}`);
  }
  DeletePreva(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-preva/${id}`);
  }
  DeleteFaltax(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-faltax/${id}`);
  }
  ExtenderPrev(prevenciones:Prevenciones):Observable<any>{
    
    return this.http.post(`${this.base}extender-prev`,prevenciones);
  }
  ExtenderSal(faltas:Faltas):Observable<any>{
    
    return this.http.post(`${this.base}extender-salarios`,faltas);
  }
  ExtenderSal2(faltas:Faltas):Observable<any>{
    
    return this.http.post(`${this.base}extender-salarios2`,faltas);
  }
  AutorizarPrev(id:any):Observable<any>{
    
    return this.http.get(`${this.base}autorizar-prev/${id}`);
  }
  DesAutorizarPrev(id:any):Observable<any>{
    
    return this.http.get(`${this.base}desautorizar-prev/${id}`);
  }
  CumplioPrev(id:any):Observable<any>{
    
    return this.http.get(`${this.base}cumplio-prev/${id}`);
  }
  CumplioNoPrev(id:any):Observable<any>{
    
    return this.http.get(`${this.base}cumpliono-prev/${id}`);
  }
  AgregarPrevenciones(prevenciones:Prevenciones):Observable<any>{
    return this.http.post(`${this.base}add-prevenciones`,prevenciones);
  }
  asignarPrevencion(prevenciones:Prevenciones):Observable<any>{
    return this.http.post(`${this.base}asignar-prevencion`,prevenciones);
  }
  asignarFalta(falta:Faltas):Observable<any>{
    return this.http.post(`${this.base}asignar-faltax2`,falta);
  }
  GetPrevencionesId(id:any):Observable<any>{
    return this.http.get(`${this.base}prevencionesbyid/${id}`);
  }
  updatePrevenciones(prevenciones:Prevenciones){
    return this.http.put<Prevenciones>(`${this.base}prevenciones-update`,prevenciones);
  }
  AgregarSancionX(prevenciones:Prevenciones):Observable<any>{
    return this.http.put<Prevenciones>(`${this.base}asignar-faltax`,prevenciones);
  }
  /* Medidas */
  getMedidas(id:any):any{
    return this.http.get<Medidas[]>(`${this.base}view-medidas/${id}`);
  }
  getMedidas3(id:any):any{
    return this.http.get<Medidas[]>(`${this.base}view-medidas3/${id}`);
  }
  getMedidas2(medida:Medidas):any{
    return this.http.post(`${this.base}view-medidas2`,medida);
  }
  getMedidas1(medida:Medidas):any{
    return this.http.post(`${this.base}view-medidas1`,medida);
  }
  DeleteMedidas(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-medidas/${id}`);
  }
  AgregarMedidas(medidas:Medidas):Observable<any>{
    return this.http.post(`${this.base}add-medidas`,medidas);
  }
  asignarMedidas(medidas:Medidas):Observable<any>{
    return this.http.post(`${this.base}asignar-medidas`,medidas);
  }
  GetMedidasId(id:any):Observable<any>{
    return this.http.get(`${this.base}medidasbyid/${id}`);
  }
  updateMedidas(medidas:Medidas){
    return this.http.put<Medidas>(`${this.base}medidas-update`,medidas);
  }
  CumplioMedida(id:any):Observable<any>{
    
    return this.http.get(`${this.base}cumplio-medida/${id}`);
  }
  CumplioNoMedida(id:any):Observable<any>{
    
    return this.http.get(`${this.base}cumpliono-medida/${id}`);
  }
  AsignarMedida(medidas:Medidas):Observable<any>{
    return this.http.post(`${this.base}asignar-medidas`,medidas);
  }
  DesasignarMedida(medidas:Medidas):Observable<any>{
    return this.http.post(`${this.base}desasignar-medidas`,medidas);
  }
  /* Pagos */
  getPagos(id:any):any{
    return this.http.get<Pagos[]>(`${this.base}view-pagos/${id}`);
  }
  getPagos2(id:any):any{
    return this.http.get<Pagos[]>(`${this.base}view-pagos2/${id}`);
  }
  getPagos3():any{
    return this.http.get<Pagos[]>(`${this.base}view-pagos3`);
  }
  getPagos4():any{
    return this.http.get<Pagos[]>(`${this.base}view-pagos4`);
  }
  DeletePagos(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-pagos/${id}`);
  }
  AgregarPagos(pagos:Pagos):Observable<any>{
    return this.http.post(`${this.base}add-pagos`,pagos);
  }
  /* Parametros */
  getParametros():any{
    return this.http.get<Parametro[]>(`${this.base}view-parametro`);
  }
  GetParametrosId(id:any):Observable<any>{
    return this.http.get(`${this.base}parametrobyid/${id}`);
  }
  updateParametros(parametro:Parametro){
    return this.http.put<Parametro>(`${this.base}parametro-update`,parametro);
  }
  /* Tags */
  getTags():any{
    return this.http.get<Tag[]>(`${this.base}view-tags`);
  }
  GetTagsId(id:any):Observable<any>{
    return this.http.get(`${this.base}tagsbyid/${id}`);
  }
  updateTags(tag:Tag){
    return this.http.put<Tag>(`${this.base}tags-update`,tag);
  }
  /* Verificacion */
  getVerificacion(id:any):any{
    return this.http.get<Verificacion[]>(`${this.base}view-verificacion/${id}`);
  }
  getVerificacion2(id:any):any{
    return this.http.get<Verificacion[]>(`${this.base}view-verificacion2/${id}`);
  }
  DeleteVerificacion(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-verificacion/${id}`);
  }
  AgregarVerificacion(verificacion:Verificacion):Observable<any>{
    return this.http.post(`${this.base}add-verificacion`,verificacion);
  }
  GetVerificacionId(id:any):Observable<any>{
    return this.http.get(`${this.base}verificacionbyid/${id}`);
  }
  updateVerificacion(verificacion:Inspeccion){
    return this.http.put<Inspeccion>(`${this.base}verificacion-update`,verificacion);
  }
  /* Verificacion */
  getVerificacion3(id:any):any{
    return this.http.get<Verificacion[]>(`${this.base}view-verificacion3/${id}`);
  }
  getVerificacion22(id:any):any{
    return this.http.get<Verificacion[]>(`${this.base}view-verificacion22/${id}`);
  }
  DeleteVerificacion2(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-verificacion2/${id}`);
  }
  AgregarVerificacion2(verificacion:Verificacion):Observable<any>{
    return this.http.post(`${this.base}add-verificacion2`,verificacion);
  }
  GetVerificacionId2(id:any):Observable<any>{
    return this.http.get(`${this.base}verificacionbyid2/${id}`);
  }
  GetVerificacionId3(id:any):Observable<any>{
    return this.http.get(`${this.base}verificacionbyid3/${id}`);
  }
  updateVerificacion2(verificacion:Inspeccion){
    return this.http.put<Inspeccion>(`${this.base}verificacion2-update`,verificacion);
  }
  /* Rutas */
  getRuta(id:any):any{
    return this.http.get<Ruta[]>(`${this.base}view-rutaexp/${id}`);
  }
  DeleteRuta(id:any):Observable<any>{
        return this.http.delete(`${this.base}delete-rutaexp/${id}`);
  }
  AgregarRuta(ruta:Ruta):Observable<any>{
    return this.http.post(`${this.base}add-rutaexp`,ruta);
  }
  GetRutaId(id:any):Observable<any>{
    return this.http.get(`${this.base}rutaexpbyid/${id}`);
  }
  updateRuta(ruta:Ruta){
    return this.http.put<Ruta>(`${this.base}rutaexp-update`,ruta);
  }
  /* Criterios */
  /*getCriterios(id:any):any{
    return this.http.get<Criterio>(`${this.base}view-criterio/${id}`);
  }*/
  getCriterios(criterio:Criterio):any{
    return this.http.post(`${this.base}view-criterio`,criterio);
  }
  getCriterios1(criterio:Criterio):any{
    return this.http.post(`${this.base}view-criterio1`,criterio);
  }
  getCriterios2(criterio:Criterio):any{
    return this.http.post(`${this.base}view-criterio2`,criterio);
  }
  DeleteCriterio(id:any):Observable<any>{
        return this.http.delete(`${this.base}delete-criterio/${id}`);
  }
  AgregarCriterio(criterio:Criterio):Observable<any>{
    return this.http.post(`${this.base}add-criterio`,criterio);
  }
  /* Bancos */
  getBancos():any{
    return this.http.get<Banco>(`${this.base}view-banco`);
  }

  cambioInspector(user:User):Observable<any>{
    return this.http.post(`${this.base}cambio-inspector`,user);
  }
  cambioInspector2(user:User):Observable<any>{
    return this.http.post(`${this.base}cambio-inspector2`,user);
  }
  cambioAsesor(user:User):Observable<any>{
    return this.http.post(`${this.base}cambio-asesor`,user);
  }

  /* Reporte */
  getReporte1(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte1`,reporte);
  }
  getReporte2(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte2`,reporte);
  }
  getReporte3(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte3`,reporte);
  }
  getReporte4(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte4`,reporte);
  }
  getReporte5(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte5`,reporte);
  }
  getReporte6(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte6`,reporte);
  }
  getReporte7(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte7`,reporte);
  }
  getReporte8(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte8`,reporte);
  }
  getReporte9(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte9`,reporte);
  }
  getReporte10(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte10`,reporte);
  }
  getReporte11(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte11`,reporte);
  }
  getReporte12(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte12`,reporte);
  }
  getReporte13(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte13`,reporte);
  }
  getReporte14(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte14`,reporte);
  }
  getReporte15(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte15`,reporte);
  }
  getReporte16(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte16`,reporte);
  }
  getReporte17(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte17`,reporte);
  }
  getReporte18(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte18`,reporte);
  }
  getReporte19(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte19`,reporte);
  }
  getReporte20(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte20`,reporte);
  }
  getReporte21(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte21`,reporte);
  }
  getReporte22(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte22`,reporte);
  }
  getReporte23(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte23`,reporte);
  }
  getReporte24(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte24`,reporte);
  }
  getReporte25(reporte:Reporte):any{
    return this.http.post(`${this.base}view-reporte25`,reporte);
  }

  /* Graficas */
  getGrafica1(grafica:Reporte):any{
    return this.http.post(`${this.base}view-grafica1`,grafica);
  }
  getGrafica2(grafica:Reporte):any{
    return this.http.post(`${this.base}view-grafica2`,grafica);
  }
  getGrafica3(grafica:Reporte):any{
    return this.http.post(`${this.base}view-grafica3`,grafica);
  }
  getGrafica4(grafica:Reporte):any{
    return this.http.post(`${this.base}view-grafica4`,grafica);
  }
  getGrafica5(grafica:Reporte):any{
    return this.http.post(`${this.base}view-grafica5`,grafica);
  }

  /* Ley */
  getLey():any{
    return this.http.get<Leyes[]>(`${this.base}view-ley`);
  }
  getLey2():any{
    return this.http.get<Leyes[]>(`${this.base}view-ley2`);
  }
  DeleteLey(id:any):Observable<any>{
    
    return this.http.delete(`${this.base}delete-ley/${id}`);
  }
  AgregarLey(datos:FormData):Observable<any>{
    return this.http.post(`${this.base}add-ley`,datos);
  }
  GetLeyId(id:any):Observable<any>{
    return this.http.get(`${this.base}leybyid/${id}`);
  }
  updateLey(datos:FormData):Observable<any>{
    return this.http.post(`${this.base}ley-update`,datos);
  }
  /* PRESTACIONES */
  AgregarSalario(datos:FormData):Observable<any>{
    return this.http.post(`${this.base}add-salario`,datos);
  }
  getSalario(id:any):any{
    return this.http.get<Prestacion[]>(`${this.base}view-salario/${id}`);
  }
  DeleteSalario(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-salario/${id}`);
  }
  DeleteSalarioFull(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-salarioff/${id}`);
  }
  /* BONIFICACION EXTRA */
  AgregarSalarioB(datos:FormData):Observable<any>{
    return this.http.post(`${this.base}add-salarioB`,datos);
  }
  getSalarioB(id:any):any{
    return this.http.get<Prestacion[]>(`${this.base}view-salarioB/${id}`);
  }
  DeleteSalarioB(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-salarioB/${id}`);
  }
  DeleteBonificacionFull(id:any):Observable<any>{
    return this.http.delete(`${this.base}delete-bonificacionff/${id}`);
  }
  
}
