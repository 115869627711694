import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { AuthService } from '../auth.service';
import { Permiso } from '../services/Permiso';
import { Permisosxrol } from '../services/Permisosxrol';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/dashboard',
  },
  {
    title: 'Tablero de Desempeño',
    icon: 'home-outline',
    link: '/pages/dashboard',
  },
  {
    title: 'Adjudicaciones',
    icon: 'credit-card-outline',
    hidden: true,
    children: [
      {
        title: 'Expedientes',
        link: '/pages/list-adjudicacion',
        hidden: true
      },
      {
        title: 'Operativos',
        link: '/pages/list-operativo',
        hidden: true
      },
    ],
  },
  {
    title: 'Inspecciones',
    icon: 'maximize-outline',
    hidden: true,
    children: [
      {
        title: 'Expedientes',
        link: '/pages/list-inspeccion',
        hidden: true
      },
    ],
  },
  {
    title: 'Administración de multas',
    icon: 'monitor-outline',
    hidden: true,
    children: [
      {
        title: 'Solicitudes de exoneración',
        link: '/pages/list-exoneracion',
        hidden: true
      },
      {
        title: 'Gestión de sanciones',
        link: '/pages/registro-pago',
        hidden: true
      },
      {
        title: 'Carga de pagos',
        link: '/pages/carga-pago',
        hidden: true
      }
    ],
  },
  {
    title: 'Impugnaciones',
    icon: 'minus-circle-outline',
    hidden: true,
    children: [
      {
        title: 'Recurso de Revocatoria',
        link: '/pages/list-impugnacion',
        hidden: true
      }
    ],
  },
  {
    title: 'Notificaciones',
    icon: 'message-circle-outline',
    hidden: true,
    children: [
      {
        title: 'Asignación',
        link: '/pages/edit6-inspeccion',
        hidden: true
      },
      {
        title: 'Resultado',
        link: '/pages/edit7-inspeccion',
        hidden: true
      },
    ],
  },
  {
    title: 'Reportes',
    icon: 'pie-chart-outline',
    hidden: true,
    children: [
      {
        title: 'Reportes',
        link: '/pages/list-reporte',
        hidden: true
      },
    ],
  },
  
  {
    title: 'Catálogos',
    icon: 'keypad-outline',
    hidden: true,
    children: [
      {
        title: 'Trabajadores',
        link: '/pages/list-trabajador',
        hidden: true
      },
      {
        title: 'Empleadores',
        link: '/pages/list-empleador',
        hidden: true
      },
      {
        title: 'Paises',
        link: '/pages/list-pais',
        hidden: true
      },
      {
        title: 'Profesiones',
        link: '/pages/list-profesion',
        hidden: true
      },
      {
        title: 'Puestos',
        link: '/pages/list-puesto',
        hidden: true
      },
      {
        title: 'Temas',
        link: '/pages/list-tema',
        hidden: true
      },
      {
        title: 'Regiones',
        link: '/pages/list-region',
        hidden: true
      },
      {
        title: 'Fundamentos de Sanción',
        link: '/pages/list-fundamento',
        hidden: true
      },
      {
        title: 'Sectores Económicos',
        link: '/pages/list-sectoreco',
        hidden: true
      },
      {
        title: 'Plantillas',
        link: '/pages/list-documento',
        hidden: true
      },
      {
        title: 'Parámetros',
        link: '/pages/list-parametro',
        hidden: true
      },
      {
        title: 'Leyes',
        link: '/pages/list-ley',
        hidden: true
      },
      {
        title: 'Tags',
        link: '/pages/list-tag',
        hidden: true
      },
      {
        title: 'Salarios Mínimos',
        link: '/pages/list-salario',
        hidden: true
      }
      
    ],
  },
  {
    title: 'Archivo',
    icon: 'attach-outline',
    hidden: true,
    children: [
      {
        title: 'Archivo',
        link: '/pages/edit10-inspeccion',
        hidden: true
      },
    ],
  },
  {
    title: 'Prestaciones',
    icon: 'gift-outline',
    hidden: true,
    children: [
      {
        title: 'Cálculo de Prestaciones',
        link: '/pages/prestaciones',
        hidden: true
      },
    ],
  },
  {
    title: 'Seguridad',
    icon: 'people-outline',
    hidden: true,
    children: [
      {
        title: 'Usuarios',
        link: '/pages/list-users',
        hidden: true
      },
      {
        title: 'Roles',
        link: '/pages/list-rol',
        hidden: true
      },
      {
        title: 'Bitácora',
        link: '/pages/list-bitacora',
        hidden: true
      },
      {
        title: 'Modificar Clave',
        link: '/pages/modificar-clave',
        hidden: true
      },
    ],
  },
  
];



