import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { AuthService } from '../../../auth.service';
import { User } from '../../../services/Users';
import { MENU_ITEMS } from '../../../pages/pages-menu';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user:User = new User();
  permisos=[];
  usere:User = new User();
  allowedRoles= [];
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'cosmic';

  //userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
  userMenu = [ /*{ title: 'Profile' },*/ { title: 'Mi perfil',link: '/pages/mi-perfil'},{ title: 'Cerrar Sesión',link: '/auth/logout'} ];
  
  constructor(private authService: NbAuthService,
    private router: Router,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService2:AuthService) {
    
      this.authService.onTokenChange()
    .subscribe((token: NbAuthSimpleToken) => {
      if (token.isValid()) {
        this.usere.token=token.getValue();
        this.authService2.getinfo(this.usere)
        .subscribe(data=>{
          this.user=data;
          localStorage.setItem('gls',this.user.id.toString());
          localStorage.setItem("username",this.user.name.toString());          
          try{localStorage.setItem("userpath",this.user.path.toString());}catch{} 
          this.findRole()
        });
      }

    });
      
}

  ngOnInit() {
    

    this.themeService.currentTheme='cosmic';
    this.currentTheme = this.themeService.currentTheme;
    this.user.name=this.user.name;
    


  }

  ngOnDestroy() {
    
  }

  findRole() {
    this.authService2.getbyuser(this.user.id)
    .subscribe(datas=>{
        this.allowedRoles=datas;
          /* DASHBOARD */
          MENU_ITEMS[0].hidden=true
          /* DASHBOARD */
          MENU_ITEMS[1].hidden=false
          /* ADJUDICACIONES */
          MENU_ITEMS[2].hidden=!this.allowedRoles.includes(57)
          MENU_ITEMS[2].children[0].hidden=!this.allowedRoles.includes(53)
          MENU_ITEMS[2].children[1].hidden=!this.allowedRoles.includes(142)
          /* INSPECCIONES */
          MENU_ITEMS[3].hidden=!this.allowedRoles.includes(139)
          MENU_ITEMS[3].children[0].hidden=!this.allowedRoles.includes(119)
          /* ADMIN MULTAS */
          MENU_ITEMS[4].hidden=!this.allowedRoles.includes(139)
          MENU_ITEMS[4].children[0].hidden=!this.allowedRoles.includes(119)
          MENU_ITEMS[4].children[1].hidden=!this.allowedRoles.includes(119)
          MENU_ITEMS[4].children[2].hidden=!this.allowedRoles.includes(119)
          /* IMPUGNACIONES */
          MENU_ITEMS[5].hidden=!this.allowedRoles.includes(139)
          MENU_ITEMS[5].children[0].hidden=!this.allowedRoles.includes(119)
          /* NOTIFICACIONES */
          MENU_ITEMS[6].hidden=!this.allowedRoles.includes(144)
          MENU_ITEMS[6].children[0].hidden=!this.allowedRoles.includes(147)          
          MENU_ITEMS[6].children[1].hidden=!this.allowedRoles.includes(148)
          /* REPORTES */
          MENU_ITEMS[7].hidden=!this.allowedRoles.includes(145)
          MENU_ITEMS[7].children[0].hidden=!this.allowedRoles.includes(119) 
          /* CATALOGOS */
          MENU_ITEMS[8].hidden=!this.allowedRoles.includes(58)              
          MENU_ITEMS[8].children[0].hidden=!this.allowedRoles.includes(49)              
          MENU_ITEMS[8].children[1].hidden=!this.allowedRoles.includes(45)              
          MENU_ITEMS[8].children[2].hidden=!this.allowedRoles.includes(33)             
          MENU_ITEMS[8].children[3].hidden=!this.allowedRoles.includes(29)              
          MENU_ITEMS[8].children[4].hidden=!this.allowedRoles.includes(25)         
          MENU_ITEMS[8].children[5].hidden=!this.allowedRoles.includes(64);
          MENU_ITEMS[8].children[6].hidden=!this.allowedRoles.includes(60);
          MENU_ITEMS[8].children[7].hidden=!this.allowedRoles.includes(88);
          MENU_ITEMS[8].children[8].hidden=!this.allowedRoles.includes(96);
          MENU_ITEMS[8].children[9].hidden=!this.allowedRoles.includes(107);
          MENU_ITEMS[8].children[10].hidden=!this.allowedRoles.includes(105);
          MENU_ITEMS[8].children[11].hidden=!this.allowedRoles.includes(150);
          MENU_ITEMS[8].children[12].hidden=!this.allowedRoles.includes(150);
          MENU_ITEMS[8].children[13].hidden=!this.allowedRoles.includes(150);
          /* ARCHIVO */
          MENU_ITEMS[9].hidden=!this.allowedRoles.includes(156)                            
          MENU_ITEMS[9].children[0].hidden=!this.allowedRoles.includes(156);
          /* PRESTACIONES */
          //MENU_ITEMS[10].hidden=!this.allowedRoles.includes(157)                            
          //MENU_ITEMS[10].children[0].hidden=!this.allowedRoles.includes(157);
          /* SEGURIDAD */
          MENU_ITEMS[11].hidden=!this.allowedRoles.includes(59)                            
          MENU_ITEMS[11].children[0].hidden=!this.allowedRoles.includes(1);            
          MENU_ITEMS[11].children[1].hidden=!this.allowedRoles.includes(5);            
          MENU_ITEMS[11].children[2].hidden=!this.allowedRoles.includes(104);   
          MENU_ITEMS[11].children[3].hidden=!this.allowedRoles.includes(160);
        
    });
  } 


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
