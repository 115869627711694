export class User{
    id:number;
    name:string;    
    lastname:string;
    email:string;
    password:string;
    TELEFONO:string;
    ESTADO:string;
    token:string;
    jname:string;
    jlastname:string;
    jefe:string;
    path:string;
    estado:string;
    INSPECTOR:string;
    SEXO:string;
}