import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      MTPS - IGT © 2024
      <div style="text-align:right; width: 75%;">
      <div style="float: right;">Elaborado por <a href="http://glorsys.com" target="_blank" > <img  src="assets/images/glorsys.png" style="width:35px;"/></a></div>
      </div>
    
  `,
})
export class FooterComponent {
}
